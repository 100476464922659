<template>
  <v-card flat class="card card-custom card-stretch gutter-b">
    <v-card-title class="font-weight-bolder text-dark table__header">
      <div>{{ title }}</div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item" @click="() => this.$emit('openCreate')">
            <v-btn class="nav-link py-1 px-4 table__buttons boton"
              >Crear grupo</v-btn
            >
          </li>
        </ul>
      </div>
    </v-card-title>
    <v-card-subtitle>{{ items.length }} </v-card-subtitle>
    <div class="card-body pt-0 pb-3" v-if="items.length !== 0">
      <v-simple-table
        class="table table-head-custom table-vertical-center table-head-bg table-bdealless bdealless"
      >
        <template v-slot:default>
          <thead>
            <tr class="text-left">
              <th
                v-for="(item, index) in headers"
                :key="item"
                :class="
                  index !== 0
                    ? 'p-0 bdealless color__error'
                    : ' bdealless color__error'
                "
              >
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in itemList" :key="item._id">
              <template>
                <!-- DESCRIPCION GRUPO -->
                <td class="py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.description }}
                  </span>
                </td>

                <!-- PRECIO OFERTA -->
                <td class="pl-0 py-4">
                  <span class="font-weight-bolder d-block font-14">
                    {{ item.amount }}
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.productList.length }}
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <v-btn icon @click="emitClick(index)">
                    <v-icon large class="color__text--primary">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="emitDelete(index)">
                    <v-icon large class="color__text--primary ml-2">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="card-body pt-0 pb-3" v-else>
      <v-img
        src="/media/misc/ZeroData.png"
        width="500px"
        height="200px"
        style="margin:0 auto;"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DealsGroupList",
  components: {},
  props: {
    items: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        "Descripción",
        "Num. Productos",
        "Prod. Seleccionables",
        "Acciones",
      ],
    };
  },
  mounted() {
    console.log(this.groupListList);
  },
  methods: {
    emitClick(dealId) {
      this.$emit("dealClicked", dealId);
    },
    emitDelete(dealId) {
      this.$emit("dealDelete", dealId);
    },
  },
  computed: {
    itemList() {
      console.log(this.items);
      return this.items;
    },
  },
  watch: {
    items: function(newValue, oldValue) {
      console.log("items");
      console.log(newValue);
    },
  },
};
</script>

<style scoped>
.table__header {
  font-size: 16px;
  justify-content: space-between;
}

.table__buttons {
  font-size: 13px;
  font-weight: 400;
}

.bdealless {
  bdeal-bottom: none !important;
}

.h-50 {
  height: 50% !important;
}

.back-grey {
  background-color: beige;
}
</style>
