<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="800px"
    :overlay-opacity="0.9"
    overlay-color="#333333"
    ref="contenedor"
  >
    <v-card class="px-6 py-6">
      <div class="closeIcon">
        <v-btn icon color="#000000" @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="card-body pt-5 pb-3">
        <v-row>
          <v-col>
            <div>
              <div class="card__title">
                Descripción
              </div>

              <v-text-field
                dense
                v-model="newGroup.description"
                outlined
                label="Descripción"
                style="min-height: 25px"
                rows="4"
                row-height="30"
                class="mt-4"
                :error-messages="newGroupDescriptionErrors"
                @blur="$v.newGroup.description.$touch()"
                @input="$v.newGroup.description.$touch()"
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div>
              <div class="card__title">
                Cantidad para seleccionar
              </div>

              <v-text-field
                dense
                v-model="newGroup.amount"
                outlined
                label="Cantidad para seleccionar"
                style="min-height: 25px"
                rows="4"
                row-height="30"
                class="mt-4"
                :error-messages="newGroupAmountErrors"
                @blur="$v.newGroup.amount.$touch()"
                @input="$v.newGroup.amount.$touch()"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-autocomplete
          v-model="newGroup.productList"
          :items="products"
          filled
          chips
          color="blue-grey lighten-2"
          label="Productos del grupo"
          item-text="name"
          item-value="_id"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
              color="green"
              small
            >
              <v-avatar left>
                <v-img :src="data.item.pic"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <!-- <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template> -->
            <template>
              <v-list-item-avatar>
                <img :src="data.item.pic" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <div class="text-right">
          <v-btn class="boton font-14 px-9 py-4 my-3" @click="saveGroup">
            <v-icon class="mr-2">
              {{ group ? "mdi-pencil" : "mdi-plus" }}
            </v-icon>
            {{ group ? "ACTUALIZAR GROUP" : "CREAR GROUP" }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { GET_PRODUCTS_BY_OWNER_ID } from "@/core/services/store/products.module";
import { mapState } from "vuex";
import {
  required,
  numeric,
  maxLength,
  minLength,
  url,
} from "vuelidate/lib/validators";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    group: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      newGroup: {
        description: null,
        amount: null,
        productList: [],
      },
    };
  },
  validations: {
    newGroup: {
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(35),
      },
      amount: { required, numeric },
    },
  },
  methods: {
    handleClose() {
      Object.assign(this.newGroup, {
        description: null,
        amount: null,
        productList: [],
      });
      this.$emit("onClose");
    },
    remove(item) {
      var filtered = this.newGroup.productList.filter((element) => {
        return element !== item._id;
      });
      this.newGroup.productList = [...filtered];
    },
    saveGroup() {
      console.log(this.newProduct);
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
        this.newGroup.amount = parseInt(this.newGroup.amount);
        this.$emit("saveGroup", this.newGroup);
      }
      Object.assign(this.newGroup, {
        description: null,
        amount: null,
        productList: [],
      });
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      products: (state) => state.products.items,
    }),
    newGroupDescriptionErrors() {
      const errors = [];
      if (!this.$v.newGroup.description.$dirty) return errors;
      !this.$v.newGroup.description.required &&
        errors.push("Es necesario introducir una descripcion.");
      !this.$v.newGroup.description.minLength &&
        errors.push("La descripción debe tener un mínimo de 5 posiciones.");
      !this.$v.newGroup.description.maxLength &&
        errors.push("La descripción debe tener un máximo de 35 posiciones.");
      return errors;
    },
    newGroupAmountErrors() {
      const errors = [];
      if (!this.$v.newGroup.amount.$dirty) return errors;
      !this.$v.newGroup.amount.required &&
        errors.push("Es necesario introducir una cantidad.");
      !this.$v.newGroup.amount.numeric &&
        errors.push("Es necesario que la cantidad sea un número");
      return errors;
    },
  },
  watch: {
    group: function(newValue, oldValue) {
      if (this.group) this.newGroup = Object.assign({}, this.group);
      this.$store.dispatch(
        GET_PRODUCTS_BY_OWNER_ID,
        this.$store.state.auth.user.seller._id
      );
    },
  },
};
</script>
<style></style>
