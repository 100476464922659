<template>
  <div class="mt-10">
    <h1 class="section__headline">Edición de oferta</h1>
    <v-breadcrumbs :items="breadcrumb" divider="->" />
    <v-container>
      <v-row class="justify-content-center">
        <div class="col-xxl-8 order-2 order-xxl-1">
          <v-card v-if="isFetching" flat min-height="400px" class="text-center">
            <h1 class="mt-4">Cargando producto ...</h1>
            <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin:0 auto;" />
          </v-card>

          <v-card v-if="!isFetching" flat class="card card-custom card-stretch gutter-b">
            <div class="card-body pt-5 pb-3">
              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">
                      Nombre
                    </div>

                    <v-text-field dense v-model="newDeal.name" outlined label="Nombre" style="min-height: 25px" rows="4"
                      row-height="30" class="mt-4" :error-messages="newDealNameErrors" @blur="$v.newDeal.name.$touch()"
                      @input="$v.newDeal.name.$touch()"></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">
                      Precio
                    </div>

                    <v-text-field dense v-model="newDeal.price" outlined label="Precio" style="min-height: 25px"
                      rows="4" row-height="30" class="mt-4" :error-messages="newDealPriceErrors"
                      @blur="$v.newDeal.price.$touch()" @input="$v.newDeal.price.$touch()"></v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">
                      Imagen
                    </div>

                    <v-text-field dense v-model="newDeal.pic" outlined label="Imagen" style="min-height: 25px" rows="4"
                      row-height="30" class="mt-4" :error-messages="newDealPicErrors" @blur="$v.newDeal.pic.$touch()"
                      @input="$v.newDeal.pic.$touch()"></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <div v-if="newDeal?.pic?.length > 8">
                  <v-img :lazy-src="newDeal?.pic" max-height="150" max-width="250" :src="newDeal?.pic"></v-img>
                </div>

              </v-row>

              <v-row>
                <v-col>
                  <div>
                    <div class="card__title">
                      Disponible local
                    </div>

                    <v-switch :input-value="newDeal.local" v-model="newDeal.local" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">
                      Disponible reparto
                    </div>

                    <v-switch :input-value="newDeal.delivery" v-model="newDeal.delivery" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">
                      Activo
                    </div>
                    <v-switch :input-value="newDeal.list" v-model="newDeal.list" />
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="card__title">
                      Swiper
                    </div>
                    <v-switch :input-value="newDeal.swiper" v-model="newDeal.swiper" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <DealsGroupList :items="itemsList" :title="'Grupos'" @dealClicked="handleClickedDeal"
                    @dealDelete="handleDeleteDeal" @openCreate="handleOpenCreate" />
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn class="boton font-14 px-9 py-4 my-3" @click="saveDeal">
                  <v-icon class="mr-2">
                    {{ id ? 'mdi-pencil' : 'mdi-plus' }}
                  </v-icon>
                  {{ id ? 'ACTUALIZAR OFERTA' : 'CREAR OFERTA' }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
    </v-container>

    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      <div class="snackbar">
        {{ snackbar.text }}
        <v-btn color="black" text @click="snackbar.show = false">
          Close
        </v-btn>
      </div>
    </v-snackbar>
    <DealsModal :isOpen="isGroupModalOpen" :group="newDeal.items[clickedGroup]" @onClose="handleOnClose"
      @saveGroup="handleSaveGroup" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  GET_DEAL,
  UPDATE_DEAL,
  CREATE_DEAL,
} from '@/core/services/store/deals.module';
import DealsGroupList from '@/components/ofertas/DealsGroupList';
import DealsModal from '@/components/ofertas/DealsModal';
import {
  required,
  decimal,
  maxLength,
  minLength,
  url,
} from 'vuelidate/lib/validators';
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";

export default {
  name: 'Ofertas',
  components: { DealsGroupList, DealsModal },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Ofertas',
          disabled: false,
          href: '/ofertas',
        },
        {
          text: 'Edición de la oferta',
          disabled: true,
        },
      ],
      filteredList: null,
      snackbar: {
        show: false,
        text: '',
      },
      id: null,
      isFetching: false,
      newDeal: {
        delivery: false,
        items: [],
        local: false,
        list: false,
        swipper: false,
        name: '',
        price: null,
        pic: null,
      },
      isGroupModalOpen: false,
      clickedGroup: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (!this.id) {
      Object.assign(this.$data, this.$options.data());
    }
    if (this.$route.query.status)
      this.showSnackbar('Producto actualizado con éxito!!  👏 👏 👏');
    this.$store.dispatch(GET_DEAL, this.id).then(() => {
      this.newDeal = this.deal;
    });
    this.filteredList = this.deals;
  },
  validations: {
    newDeal: {
      name: { required, minLength: minLength(5), maxLength: maxLength(30) },
      price: {
        required,
        decimal,
      },
      pic: {
        url,
      },
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      deal: (state) => state.deals.item,
      isFetching: (state) => state.deals.isFetching,
    }),
    itemsList() {
      return this.newDeal.items;
    },
    newDealNameErrors() {
      const errors = [];
      if (!this.$v.newDeal.name.$dirty) return errors;
      !this.$v.newDeal.name.required &&
        errors.push('Es necesario introducir un nombre.');
      !this.$v.newDeal.name.minLength &&
        errors.push('El nombre debe tener como mínimo 5 caracteres.');
      !this.$v.newDeal.name.maxLength &&
        errors.push('El nombre no debe tener mas de 20 caracteres.');
      return errors;
    },
    newDealPriceErrors() {
      const errors = [];
      if (!this.$v.newDeal.price.$dirty) return errors;
      !this.$v.newDeal.price.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newDeal.price.decimal &&
        errors.push('El precio debe ser un valor numérico.');
      return errors;
    },
    newDealPicErrors() {
      const errors = [];
      if (!this.$v.newDeal.pic.$dirty) return errors;
      !this.$v.newDeal.pic.url &&
        errors.push(
          'Es necesario introducir una dirección correcta para la imagen.'
        );
      return errors;
    },
  },
  methods: {
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    handleClickedDeal(index) {
      this.clickedGroup = index;
      this.isGroupModalOpen = !this.isGroupModalOpen;
    },
    handleDeleteDeal(index) {
      this.$confirm('Está seguro de que desea eliminar el grupo??', {
        color: '#2196f3',
        title: 'Confirmar acción',
        buttonTrueText: 'Eliminar',
        buttonFalseText: 'Cancelar',
      }).then((res) => {
        if (res) {
          let newArray = [...this.newDeal.items];
          newArray.splice(index, 1);
          this.newDeal.items = newArray;
        }
      });
    },
    handleOpenCreate() {
      this.clickedGroup = null;
      this.isGroupModalOpen = !this.isGroupModalOpen;
    },
    handleOnClose() {
      this.isGroupModalOpen = !this.isGroupModalOpen;
      this.clickedGroup = null;
    },
    handleSaveGroup(group) {
      // Para reconocer si viene de creación o de edición
      let newGroup = {};
      Object.assign(newGroup, group);

      if (this.clickedGroup !== null) {
        this.newDeal.items[this.clickedGroup] = newGroup;
        this.newDeal.items = [...this.newDeal.items];
        this.showSnackbar('Grupo actualizado con éxito!');
      } else {
        this.newDeal.items.push(newGroup);
        this.showSnackbar('Grupo creado con éxito!');
      }

      this.isGroupModalOpen = !this.isGroupModalOpen;
      this.clickedGroup = null;
    },
    saveDeal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
        this.newDeal.owner = this.currentUser.seller._id;
        this.newDeal.price = parseFloat(this.newDeal.price).toFixed(2);
        if (this.id) this.$store.dispatch(UPDATE_DEAL, this.newDeal);
        else this.$store.dispatch(CREATE_DEAL, this.newDeal);
      }
    },
  },
};
</script>
<style>
.card__title {
  display: block;
  padding-top: 4px;
  font-size: 16px !important;
  font-weight: 700;
  color: #333333;
}
</style>
